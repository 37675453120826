import * as React from 'react';

import styled, { css } from 'styled-components';
import {
    COLOR_GRAY,
    ERROR_COLOR,
    INPUT_BORDER_COLOR,
    INPUT_LABEL_COLOR,
    INPUT_PLACEHOLDER_COLOR,
    INPUT_TEXT_COLOR,
    INPUT_TEXT_DISABLED,
} from '../style';
import { formatUsingMask } from '../utils/format';

const LabelContainer = styled.div<{ isInvalid: boolean }>`
    position: absolute;
    padding: 0px 4px;
    top: -2px;
    left: 15px;

    font-size: 12px;
    letter-spacing: 0.15px;

    background-color: white;
    color: ${props => (props.isInvalid ? ERROR_COLOR : INPUT_LABEL_COLOR)};
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const FocusedStyle = css`
    border: 2px solid black;
`;

const InvalidStyle = css`
    border: 1px solid ${ERROR_COLOR};
`;

const StyledInput = styled.input<{ isFocused: boolean; isInvalid: boolean; isFilled: boolean; disabled: boolean }>`
    color: ${props => (props.disabled ? INPUT_TEXT_DISABLED : INPUT_TEXT_COLOR)};
    padding: 12px 12px;
    margin: 7px 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;

    border-radius: 8px;
    border: 1px solid ${props => (props.isFilled ? INPUT_LABEL_COLOR : INPUT_BORDER_COLOR)};
    ::placeholder {
        font-size: 14px;
        color: ${INPUT_PLACEHOLDER_COLOR};
    }
    background-color: ${props => (props.disabled ? COLOR_GRAY : 'white')};

    ${props => (props.isInvalid ? InvalidStyle : props.isFocused ? FocusedStyle : '')};
`;

const InvalidMessageText = styled.div`
    text-align: left;

    color: ${ERROR_COLOR};
    font-size: 12px;
    margin: 0px 0px 10px 16px;
`;

interface InputProps extends React.PropsWithChildren {
    id: string;
    value: string;
    label: string;
    placeholder: string;
    onValueChange: (text: string) => void;
    mask?: string;
    invalidMessage?: string;
    maxLength?: number;
    disabled?: boolean;
}

function Input({
    id,
    value,
    label,
    placeholder,
    onValueChange,
    mask,
    invalidMessage,
    maxLength,
    disabled,
}: InputProps) {
    const [isFocused, setIsFocused] = React.useState(false);

    const displayAsInvalid = !!invalidMessage && !!value?.length && (!isFocused || value?.length === maxLength);
    const isFilled = !invalidMessage && !!value?.length;

    function handleOnChange(newValue: string) {
        newValue = newValue.trimStart(); // remove leading spaces

        onValueChange(mask ? formatUsingMask(newValue, mask) : newValue);
    }

    return (
        <Container>
            <LabelContainer isInvalid={displayAsInvalid}>{label}</LabelContainer>
            <StyledInput
                id={id}
                name={id}
                value={value}
                isFocused={isFocused}
                isInvalid={displayAsInvalid}
                isFilled={isFilled}
                type="text"
                placeholder={placeholder}
                onBlur={() => setIsFocused(false)}
                onFocus={() => setIsFocused(true)}
                onChange={e => handleOnChange(e.target.value)}
                autoComplete="on"
                maxLength={maxLength}
                disabled={!!disabled}
            />
            {displayAsInvalid && <InvalidMessageText>{invalidMessage}</InvalidMessageText>}
        </Container>
    );
}

export default Input;
