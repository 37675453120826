import VendahLogo from './icons/VendahLogo';

import styled from 'styled-components';

const SpinnerContainer = styled.div`
    height: 100%;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1ch;
`;

function Spinner(): JSX.Element {
    return (
        <SpinnerContainer>
            <VendahLogo heartbeat />
            <p>Carregando...</p>
        </SpinnerContainer>
    );
}

export default Spinner;
