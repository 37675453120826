export function getOperatingSystem(): 'ios' | 'android' | undefined {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return 'android';
    }

    if (/iPhone/.test(userAgent)) {
        return 'ios';
    }

    return undefined;
}
