import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import {
    formatOnlyDigits,
    formatPhone,
    formatUsingMask,
    removeTrailingNonDigits,
    stripNonLetters,
} from '../utils/format';
import { isCpfValid } from '../utils/validation/cpf';
import { isPhoneValid } from '../utils/validation/phone';
import { Input, LogoContainer, SubmitButton, Title, VendahLogo } from '../components';
import { AlertView, Container, InputsContainer, Padding, TitleContainer } from './styles';
import { useLocalStorageState, useLocalStorageStateBool } from '../utils/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { StoreResellerRequest } from '../container/Registration.container';
import Info from '../components/icons/Info';
import Checkbox from '../components/Checkbox';
import { useAsyncEffect } from '../hook/useAsyncEffect';
import { executeRequest } from '../client/utils';
import urlJoin from 'url-join';
import { OPS_API_URL } from '../const';

const TermsAcceptedView = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;
`;

const Form = styled.form``;

interface Props {
    storeReseller: Partial<StoreResellerRequest>;
    resellerId?: string;
}

function RegistrationPersonalDataPage({ storeReseller, resellerId }: Props) {
    const [firstName, setFirstName] = useLocalStorageState('register.firstName');
    const [lastName, setLastName] = useLocalStorageState('register.lastName');
    const [age, setAge] = useLocalStorageState('register.age');
    const [phone, setPhone] = useLocalStorageState('register.phone');
    const [cpf, setCPF] = useLocalStorageState('register.cpf');
    const [termsAccepted, setTermsAccepted] = useLocalStorageStateBool('register.termsAccepted', false);
    const [phoneInvalidMessage, setPhoneInvalidMessage] = React.useState<string>();
    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate();

    const phoneInvalid = React.useMemo(() => !isPhoneValid(phone), [phone]);
    const taxIdInvalid = React.useMemo(() => !isCpfValid(cpf), [cpf]);
    const isAgeValid = age && parseInt(age) >= 18;
    const showAgeAlert = age.length > 0 && !isAgeValid;

    const submitEnabled =
        firstName && lastName && !taxIdInvalid && !phoneInvalid && isAgeValid && termsAccepted && !phoneInvalidMessage;

    useAsyncEffect(
        async () => {
            if (phoneInvalid) return;

            setLoading(true);
            try {
                const storeResellerResponse = await executeRequest(
                    urlJoin(OPS_API_URL, 'store', 'reseller', 'phone', formatOnlyDigits(phone)),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                    { ignoreStatus: true }
                );

                if (storeResellerResponse.success) {
                    if (storeResellerResponse.availability === 'IN_USE') {
                        return 'Celular já cadastrado em outra conta';
                    }
                    if (storeResellerResponse.availability === 'BLOCKED') {
                        return 'Celular está bloqueado para uso';
                    }
                }
                return undefined;
            } catch (error) {
                Sentry.captureException(error);
                setLoading(false);
                return undefined;
            }
        },
        invalidMessage => {
            setPhoneInvalidMessage(invalidMessage);
            setLoading(false);
        },
        [phone]
    );

    function submitForm(evt: any) {
        evt.preventDefault();
        if (!submitEnabled) return;

        const approxBirthDate = new Date();
        approxBirthDate.setMonth(approxBirthDate.getMonth() - 12 * parseInt(age) - 6);

        if (!storeReseller.extra_fields) storeReseller.extra_fields = {};
        storeReseller.extra_fields['approx_birth_date'] = [approxBirthDate.toISOString().substring(0, 10)];
        storeReseller.extra_fields['age'] = [age];

        const storeResellerRequest = {
            ...storeReseller,
            first_name: firstName,
            last_name: lastName,
            phone: formatPhone(phone),
            cpf,
        };

        navigate('/cadastro/endereco', { state: { storeReseller: storeResellerRequest, resellerId } });
    }

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>Cadastro</Title>
            </TitleContainer>

            <Form onSubmit={submitForm}>
                <InputsContainer>
                    <Input
                        id="firstName"
                        value={firstName}
                        label="Primeiro Nome"
                        onValueChange={text => setFirstName(stripNonLetters(text))}
                        placeholder="Joana"
                    />
                    <Input
                        id="lastName"
                        value={lastName}
                        label="Sobrenome"
                        onValueChange={text => setLastName(stripNonLetters(text))}
                        placeholder="Silva"
                    />
                    <Input
                        id="phone"
                        value={phone}
                        label="Celular"
                        placeholder="(11) 99999-9999"
                        onValueChange={text => {
                            setPhone(removeTrailingNonDigits(formatPhone(text)));
                        }}
                        invalidMessage={phoneInvalid ? 'Celular inválido' : phoneInvalidMessage}
                    />
                    <Input
                        id="age"
                        value={age}
                        label="Idade"
                        placeholder="18"
                        mask="999"
                        maxLength={3}
                        onValueChange={setAge}
                    />
                    <Input
                        id="cpf"
                        value={cpf}
                        label="CPF"
                        placeholder="000.000.000-00"
                        onValueChange={text => {
                            setCPF(removeTrailingNonDigits(formatUsingMask(text, '999.999.999-99')));
                        }}
                        invalidMessage={taxIdInvalid ? 'CPF inválido' : undefined}
                    />
                    {showAgeAlert && (
                        <AlertView>
                            <Info />
                            <p>É necessário ter pelo menos 18 anos para se cadastrar na Vendah</p>
                        </AlertView>
                    )}
                    <TermsAcceptedView>
                        <Checkbox
                            checked={termsAccepted}
                            onChange={() => {
                                setTermsAccepted(!termsAccepted);
                            }}
                        />
                        <Padding />
                        <p>
                            Aceito os{' '}
                            <a target="_blank" rel="noreferrer" href="https://vendah.com.br/pages/termos-e-condicoes">
                                Termos e Condições de Uso
                            </a>
                            .
                        </p>
                    </TermsAcceptedView>
                </InputsContainer>
                <SubmitButton disabled={!submitEnabled || loading}>
                    <Title>Avançar</Title>
                </SubmitButton>
            </Form>
        </Container>
    );
}

export default RegistrationPersonalDataPage;
