import * as React from 'react';

import styled from 'styled-components';
import { OrderContext } from '../container/Order.container';
import { LinkButton } from './Button';
import VendahLogo from './icons/VendahLogo';
import Title from './Title';

export const LayoutContainer = styled.div`
    height: 100%;

    display: flex;
    flex-direction: column;
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-top: 24px;
`;

export const FooterContainer = styled.footer`
    text-align: center;
    margin-top: auto;
    flex-shrink: 0;
`;

function OrderStatusFooter({ resellerPhone, orderName }: { orderName: string; resellerPhone?: string }) {
    if (!resellerPhone) return null;

    const phone = resellerPhone.replace('+55', '').replace(/\D/g, '');
    const whatsappLink = `https://api.whatsapp.com/send?phone=55${phone}&text=${encodeURIComponent(
        `Preciso de ajuda com meu pedido ${orderName}`
    )}`;

    return (
        <FooterContainer>
            <Title>Ficou com alguma dúvida?</Title>
            <LinkButton href={whatsappLink}>
                <Title>Falar com seu contato Vendah</Title>
            </LinkButton>
        </FooterContainer>
    );
}

function OrderStatusLayout({ children }: React.PropsWithChildren) {
    return (
        <LayoutContainer>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <section>{children}</section>
            <OrderContext.Consumer>
                {order =>
                    order && <OrderStatusFooter resellerPhone={order.reseller.phone} orderName={order.order_name} />
                }
            </OrderContext.Consumer>
        </LayoutContainer>
    );
}

export default OrderStatusLayout;
