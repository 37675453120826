import * as React from 'react';

import './App.css';
import { Route, Routes, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import OrderContainer from './container/Order.container';
import ErrorBoundary from './screens/Error.page';
import urlJoin from 'url-join';
import { VENDAH_MAIN_URL } from './const';
import CheckoutContainer from './container/Checkout.container';
import RegistrationContainer from './container/Registration.container';
import RegistrationMessageContainer from './container/RegistrationMessage.container';

function NotFound() {
    return <ErrorBoundary error={new Error('Página não encontrada!')} />;
}

function RedirectToStore({ suffix }: { suffix?: string }) {
    const { customName } = useParams();

    const r = suffix ? `${customName}/${suffix}` : customName;

    window.location.replace(urlJoin(VENDAH_MAIN_URL, 'pages', `loja?r=${r}`));

    return null;
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
    return (
        <SentryRoutes>
            <Route path="/*/pedido/:orderHash" element={<OrderContainer />} />
            <Route path="/pedido/:orderHash" element={<OrderContainer />} />
            <Route path="/checkout/:resellerId/:items" element={<CheckoutContainer />} />
            <Route path="/checkout/:items" element={<CheckoutContainer />} />
            <Route path="/cadastro/:step" element={<RegistrationContainer />} />
            <Route path="/mensagem" element={<RegistrationMessageContainer />} />
            <Route path="/:customName/sp" element={<RedirectToStore suffix="sp" />} />
            <Route path="/:customName" element={<RedirectToStore />} />
            <Route path="*" element={<NotFound />} />
        </SentryRoutes>
    );
}

export default App;
