import urlJoin from 'url-join';
import { OPS_API_URL } from '../../../const';
import { executeRequest } from '../../utils';

export type ShippingInfoResponse = {
    success: boolean;
    status_code: number;
    error?: string;
    errorCode?: string;
    ship_to_customer_fee: number;
    ship_to_reseller_fee: number;
    ship_to_reseller_workdays: number;
    ship_to_customer_workdays: number;
    has_delay: boolean;
    price_to_reseller_free_shipping: number;
    price_to_customer_free_shipping: number;
};

export interface Dimensions {
    length: number;
    width: number;
    height: number;
}

export type ShippingInfoRequest = {
    reseller_id: string;
    postal_code?: string;
    auto_checkout: boolean;
};

export async function getShippingInfo(request: ShippingInfoRequest): Promise<ShippingInfoResponse> {
    return await executeRequest(
        urlJoin(OPS_API_URL, 'orders', 'shipment', 'simulate'),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        },
        {
            ignoreStatus: true,
        }
    );
}
