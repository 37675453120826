import * as React from 'react';

import styled, { css } from 'styled-components';
import { BLUE_PRIMARY_COLOR, COLOR_BLUE, PINK_PRIMARY_COLOR } from '../style';

const commonStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 12px 5px;
    border-radius: 8px;

    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;

    cursor: pointer;
    user-select: none;
`;

const StyledLink = styled.a`
    ${commonStyle}
    margin: 10px 16px 16px 16px;
    color: white;
    ${props =>
        props.color === 'blue' &&
        css`
            background: ${BLUE_PRIMARY_COLOR};
        `}
    ${props =>
        props.color === 'pink' &&
        css`
            background: ${PINK_PRIMARY_COLOR};
        `}
    ${props =>
        props.color === 'disabled' &&
        css`
            background: rgba(0, 0, 0, 0.12);
        `}
`;

const StyledSubmitButton = styled.button`
    ${commonStyle}
    margin: 10px 16px 16px 16px;
    color: white;
    border: 0;
    background: ${props => (props.color === 'pink' ? PINK_PRIMARY_COLOR : 'rgba(0, 0, 0, 0.12)')};
`;

const StyledActionButton = styled.button`
    ${commonStyle}
    margin: 10px 16px 16px 16px;
    color: white;
    border: 0;
    ${props =>
        props.color === 'blue' &&
        css`
            background: ${BLUE_PRIMARY_COLOR};
        `}
    ${props =>
        props.color === 'pink' &&
        css`
            background: ${PINK_PRIMARY_COLOR};
        `}
    ${props =>
        props.color === 'disabled' &&
        css`
            background: rgba(0, 0, 0, 0.12);
        `}
`;

const StyledButton = styled.button`
    ${commonStyle}
    margin: 0px 4px 16px 4px;
    ${props =>
        props.color === 'white' &&
        css`
            background: white;
            color: black;
            border: 1px solid black;
        `}
    ${props =>
        props.color === 'pink' &&
        css`
            background: ${PINK_PRIMARY_COLOR};
            color: white;
            border: 1px solid ${PINK_PRIMARY_COLOR};
        `}
    ${props =>
        props.color === 'blue' &&
        css`
            background: ${COLOR_BLUE};
            color: white;
            border: 1px solid ${COLOR_BLUE};
        `}
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface LinkButtonProps extends React.PropsWithChildren {
    text?: string;
    href?: string;
    color?: 'blue' | 'pink';
    disabled?: boolean;
    selected?: boolean;
    onPress?: () => void;
}

function LinkButton({ text, href, color = 'blue', disabled, children }: LinkButtonProps) {
    return <StyledLink {...{ href, color: disabled ? 'disabled' : color }}>{text ?? children}</StyledLink>;
}

function SubmitButton({ text, color = 'pink', disabled, children }: LinkButtonProps) {
    return (
        <ButtonContainer>
            <StyledSubmitButton {...{ color: disabled ? 'disabled' : color }} type="submit">
                {text ?? children}
            </StyledSubmitButton>
        </ButtonContainer>
    );
}

function ActionButton({ text, color = 'pink', disabled, children, onPress }: LinkButtonProps) {
    return (
        <ButtonContainer>
            <StyledActionButton {...{ color: disabled ? 'disabled' : color }} onClick={onPress}>
                {text ?? children}
            </StyledActionButton>
        </ButtonContainer>
    );
}

function Button({ text, color, selected, children, onPress }: LinkButtonProps) {
    const buttonColor = color !== undefined ? color : selected ? 'pink' : 'white';
    return (
        <ButtonContainer>
            <StyledButton {...{ color: buttonColor }} type="button" onClick={onPress}>
                {text ?? children}
            </StyledButton>
        </ButtonContainer>
    );
}

export { LinkButton, SubmitButton, ActionButton, Button };
