import * as React from 'react';

export function useLocalStorageState(key: string): [string, (newValue: string) => void] {
    const [state, setState] = React.useState(() => localStorage.getItem(key) || '');
    React.useEffect(() => {
        localStorage.setItem(key, state);
    }, [key, state]);
    return [state, setState];
}

export function useLocalStorageStateBool(key: string, defaultValue: boolean): [boolean, (newValue: boolean) => void] {
    const [state, setState] = React.useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue == null ? defaultValue : storedValue === 'true';
    });
    React.useEffect(() => {
        localStorage.setItem(key, state.toString());
    }, [key, state]);
    return [state, setState];
}

export function useLocalStorageStateObj<S>(key: string, defaultValue: S): [S, React.Dispatch<React.SetStateAction<S>>] {
    const [state, setState] = React.useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : defaultValue;
    });
    React.useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}
