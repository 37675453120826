export function isCpfValid(cpf: string): boolean {
    if (!cpf.match(/^(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{11})$/)) {
        return false;
    }

    cpf = cpf.replace(/[.-]/g, '');

    // 11 identical digits is invalid
    if (cpf.match(/^(\d)\1{10}$/)) {
        return false;
    }

    let code1 = 0,
        code2 = 0;

    for (let index = 0; index < 9; index++) {
        const digit = digitAt(cpf, index);
        code1 += (10 - index) * digit;
        code2 += (11 - index) * digit;
    }

    code1 = (code1 * 10) % 11;
    code1 = code1 === 10 ? 0 : code1;

    code2 += 2 * code1;
    code2 = (code2 * 10) % 11;
    code2 = code2 === 10 ? 0 : code2;

    return digitAt(cpf, 9) === code1 && digitAt(cpf, 10) === code2;
}

function digitAt(value: string, index: number): number {
    return value.charCodeAt(index) - 48;
}
