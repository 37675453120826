import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const heartbeat = keyframes`
    0% {
        transform: scale(1);
    }

    14% {
        transform: scale(1.3);
    }

    28% {
        transform: scale(1);
    }

    42% {
        transform: scale(1.3);
    }

    70% {
        transform: scale(1);
    }`;

const PathWithHeartbeat = styled('path')`
    animation-name: ${heartbeat};
    animation-duration: 1.3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
`;

function VendahLogo({ heartbeat = false }) {
    const Heartpath = (props: Omit<React.SVGProps<SVGPathElement>, 'ref'>) =>
        heartbeat ? <PathWithHeartbeat {...props} /> : <path {...props} />;

    return (
        <svg
            width="140"
            height="25"
            viewBox="0 0 140 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ overflow: heartbeat ? 'visible' : 'hidden' }}
        >
            <Heartpath
                d="M35.8258 8.34061C35.6541 8.34061 35.4837 8.27616 35.3531 8.14728C35.0919 7.88951 35.0919 7.47185 35.3531 7.21407L39.9898 2.63864C40.2498 2.38087 40.673 2.38087 40.9341 2.63864C41.1965 2.8964 41.1965 3.31406 40.9341 3.57184L36.2983 8.14728C36.1678 8.27616 35.9961 8.34061 35.8256 8.34061"
                fill="#E13894"
            />
            <Heartpath
                d="M34.1539 9.60413L19.8116 23.7823C18.1661 25.4052 15.4965 25.4052 13.852 23.7823L6.50195 16.5279L13.1918 9.9252L15.2738 11.979C16.4636 13.158 17.935 13.8096 19.4187 13.8096C20.5468 13.8096 21.67 13.4313 22.6638 12.7093C23.029 12.4503 23.2611 12.041 23.2962 11.5923C23.3324 11.1436 23.168 10.7081 22.8464 10.3906L17.3148 4.9323L20.837 1.46551C22.2359 0.0836211 24.5089 0.0836194 25.9079 1.47027L34.1539 9.60413Z"
                fill="#E13894"
            />
            <Heartpath
                d="M22.2682 12.177C21.3456 12.8453 20.3735 13.1496 19.4195 13.1496C18.7558 13.1496 18.0956 12.9992 17.4765 12.7224C17.2444 12.6174 17.0171 12.4945 16.7946 12.3537C16.4246 12.1198 16.0728 11.8381 15.7475 11.5135C14.4417 10.2235 13.2737 9.07195 13.1976 8.99676L11.9679 10.2068L11.6149 10.5541C11.2545 10.9145 10.8749 11.288 10.5279 11.6317L6.03479 16.0577L2.47398 12.5421C-0.36012 9.74602 -0.0626944 5.04178 2.80768 2.2089C4.3009 0.739885 6.25365 0 8.20523 0C10.0152 0 11.9087 0.53224 13.27 1.87237C15.6084 4.17436 22.3745 10.8571 22.3745 10.8571C22.7578 11.2354 22.7046 11.8643 22.2681 12.1769"
                fill="#3AC2D7"
            />
            <Heartpath
                d="M10.5281 11.6299C10.8763 11.2862 11.2547 10.9127 11.6162 10.5523L6.03516 16.0549L10.5281 11.6299ZM13.1929 8.99023L11.9681 10.2039L13.1978 8.99503L13.1929 8.99023Z"
                fill="white"
            />
            <Heartpath
                d="M34.111 6.64959C33.9405 6.64959 33.7688 6.58515 33.6383 6.45626C33.3771 6.1985 33.3771 5.78084 33.6383 5.52306L36.5667 2.63278C36.8266 2.37501 37.251 2.37501 37.511 2.63278C37.7733 2.89054 37.7733 3.3082 37.511 3.56598L34.5838 6.45626C34.4532 6.58515 34.2815 6.64959 34.111 6.64959Z"
                fill="#E13894"
            />
            <Heartpath
                d="M32.3991 4.96208C32.2274 4.96208 32.0569 4.89764 31.9263 4.76876C31.6652 4.511 31.6652 4.09333 31.9263 3.83556L33.7327 2.0527C33.9927 1.79493 34.4158 1.79493 34.677 2.0527C34.9382 2.31046 34.9382 2.72812 34.677 2.9859L32.8719 4.76876C32.7413 4.89764 32.5708 4.96208 32.3991 4.96208Z"
                fill="#E13894"
            />
            <path
                d="M81.7778 7.88235C77.7236 7.88235 75.002 10.2118 75.002 14.7347V20.255C75.002 20.5032 75.0394 20.6619 75.1059 20.761C75.1072 20.7646 75.1084 20.7669 75.1096 20.7693C75.1144 20.7753 75.1168 20.7836 75.1217 20.7896C75.3284 21.2407 75.7854 21.5557 76.3199 21.5557C77.0466 21.5557 77.6366 20.9734 77.6366 20.255H77.6402V14.7346C77.6402 11.8014 79.2785 10.2942 81.7777 10.2942C84.2768 10.2942 85.9151 11.8014 85.9151 14.7346V20.255H85.9187C85.9187 20.9734 86.5088 21.5557 87.2354 21.5557C87.9633 21.5557 88.5533 20.9734 88.5533 20.255V14.7346C88.5533 10.1842 85.8317 7.88232 81.7775 7.88232"
                fill="#3AC2D7"
            />
            <path
                d="M139.505 14.5207C139.505 9.78793 136.592 7.67816 133.183 7.67816C131.156 7.67816 129.548 8.58152 128.606 10.2522V4.52536V4.52292C128.606 3.80451 128.017 3.22217 127.29 3.22217C126.562 3.22217 125.972 3.80451 125.972 4.52292V20.3899H125.986C126.055 21.0438 126.609 21.5558 127.29 21.5558C127.97 21.5558 128.524 21.0438 128.593 20.3899H128.606V20.2574V20.255V20.2525V14.5208C128.606 11.6197 130.299 10.0886 132.739 10.0886C135.235 10.0886 136.871 11.5922 136.871 14.5208V20.3695H136.883C136.942 21.033 137.501 21.5557 138.187 21.5557C138.868 21.5557 139.422 21.0438 139.491 20.3898H139.505L139.505 14.5207Z"
                fill="#3AC2D7"
            />
            <path
                d="M54.8154 8.56327C54.2797 8.56327 53.8203 8.87952 53.616 9.33178H53.6147L53.5579 9.47499L49.8751 18.6555C49.8509 18.7128 49.8449 18.739 49.8352 18.7629C49.8267 18.7844 49.8147 18.8047 49.8026 18.8261C49.7965 18.8345 49.7905 18.844 49.782 18.8548C49.7083 18.9586 49.6031 18.9992 49.4737 18.8416C49.4205 18.7653 49.4314 18.7581 49.3903 18.6567L45.6676 9.37228C45.664 9.36394 45.6603 9.35438 45.6567 9.34604L45.6507 9.33052H45.6482C45.4426 8.87823 44.9844 8.56201 44.4488 8.56201C43.7209 8.56201 43.1309 9.14435 43.1309 9.86157C43.1309 10.0286 43.1659 10.1874 43.2252 10.3341C43.2288 10.4153 43.2445 10.5012 43.2856 10.5967L47.3711 20.2651C47.7519 21.166 48.6431 21.7532 49.6321 21.7532H49.6429C49.7457 21.7532 49.8449 21.7448 49.944 21.7329C49.9476 21.7329 49.9537 21.7329 49.9561 21.7305C50.3346 21.6815 50.6888 21.5407 50.9984 21.3354C51.0249 21.3175 51.054 21.3008 51.0782 21.2829C51.1338 21.2424 51.1858 21.1982 51.2378 21.1541C51.2523 21.1409 51.268 21.129 51.2813 21.1171C51.3357 21.0681 51.3865 21.0168 51.436 20.9643C51.4409 20.9584 51.4457 20.9524 51.4506 20.9476C51.5159 20.876 51.5763 20.8008 51.6331 20.7221C51.673 20.6672 51.7093 20.6147 51.7432 20.561C51.7456 20.5562 51.7492 20.5514 51.7516 20.5466C51.9862 20.1719 52.0684 19.8533 52.0684 19.8533C52.0648 19.8616 52.0599 19.8664 52.0563 19.8748L55.987 10.4545L56.0716 10.2492L56.0776 10.2337V10.2301C56.1139 10.112 56.1321 9.98904 56.1321 9.86136C56.1321 9.14295 55.5432 8.563 54.8154 8.563"
                fill="#3AC2D7"
            />
            <path
                d="M60.5634 13.4916C61.0784 11.408 62.7566 10.2326 64.9766 10.2326C67.1421 10.2326 68.9027 11.3806 69.4165 13.4916H60.5634ZM64.9778 7.88053C60.7543 7.88053 57.7764 10.8198 57.7764 14.8282C57.7764 18.8378 60.7544 21.777 64.9778 21.777C66.8157 21.777 68.4189 21.2269 69.6328 20.271C69.7562 20.1744 69.8493 20.0622 69.9291 19.9464C70.1769 19.7101 70.3317 19.3796 70.3317 19.0132C70.3317 18.2948 69.7417 17.7125 69.015 17.7125C68.5882 17.7125 68.2121 17.9165 67.9715 18.2268H67.9667C67.4516 18.8605 66.4312 19.4226 64.9779 19.4226C62.6492 19.4226 60.8596 18.1147 60.4813 15.7636H71.1758C71.4358 15.7636 71.674 15.6598 71.8469 15.4891C72.0186 15.3197 72.1238 15.0846 72.1238 14.828C72.1238 10.8196 69.2014 7.88037 64.978 7.88037"
                fill="#3AC2D7"
            />
            <path
                d="M115.848 19.4239C113.086 19.4239 111.245 17.5002 111.245 14.8283C111.245 12.1564 113.086 10.2327 115.848 10.2327C118.581 10.2327 120.423 12.1564 120.423 14.8283C120.423 17.5002 118.581 19.4239 115.848 19.4239ZM115.873 7.88066C111.625 7.88066 108.647 10.7674 108.647 14.9083C108.647 19.2916 111.948 21.7771 115.441 21.7771C116.941 21.7771 118.285 21.3153 119.293 20.4633C119.738 20.0873 120.423 20.3964 120.423 20.9728H120.46C120.657 21.4442 121.128 21.7771 121.676 21.7771C122.28 21.7771 122.784 21.3738 122.939 20.8272C122.948 20.8057 122.954 20.7819 122.962 20.7568C122.964 20.7461 122.967 20.7353 122.969 20.7258C122.983 20.6554 122.994 20.5766 122.994 20.4776V14.9368C122.994 10.6323 120.015 7.88037 115.873 7.88037"
                fill="#3AC2D7"
            />
            <path
                d="M98.6316 19.4239C95.8701 19.4239 94.0286 17.5002 94.0286 14.8283C94.0286 12.1564 95.8701 10.2327 98.6316 10.2327C101.365 10.2327 103.207 12.1564 103.207 14.8283C103.207 17.5002 101.365 19.4239 98.6316 19.4239ZM104.493 3.2515C103.784 3.2515 103.207 3.81954 103.207 4.5212C103.207 4.52478 103.208 4.52835 103.208 4.53196C103.208 4.5344 103.207 4.53553 103.207 4.53792V10.5526C102.232 8.84247 100.391 7.88064 98.2267 7.88064C94.7324 7.88064 91.4316 10.3664 91.4316 14.7495C91.4316 18.8893 94.4072 21.7771 98.6573 21.7771C102.801 21.7771 105.779 19.0253 105.779 14.7207V4.53789C105.779 4.53667 105.778 4.53431 105.778 4.53312C105.778 4.52835 105.779 4.52478 105.779 4.52118C105.779 3.81948 105.203 3.25146 104.494 3.25146"
                fill="#3AC2D7"
            />
        </svg>
    );
}

export default VendahLogo;
