import styled from 'styled-components';
import { ReactComponent as CopySVG } from './copy.svg';

const CopyView = styled.div`
    display: flex;
`;

function Copy() {
    return (
        <CopyView>
            <CopySVG />
        </CopyView>
    );
}

export default Copy;
