import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import urlJoin from 'url-join';

import { formatUsingMask, removeTrailingNonDigits } from '../utils/format';
import { executeRequest } from '../client/utils';
import { LogoContainer, SubmitButton, Title, VendahLogo } from '../components';
import { AlertView, Container, Padding, TitleContainer } from './styles';
import AddressForm, { Address } from '../components/AddressForm';
import { useNavigate } from 'react-router-dom';
import { StoreResellerRequest } from '../container/Registration.container';
import { useLocalStorageStateObj } from '../utils/useLocalStorage';
import { useAsyncEffect } from '../hook/useAsyncEffect';
import { OPS_API_URL } from '../const';
import Info from '../components/icons/Info';

const AlertContainer = styled.div`
    margin: 0px 16px;
`;

const Form = styled.form``;

interface Props {
    storeReseller: Partial<StoreResellerRequest>;
    resellerId?: string;
}

function RegistrationAddressPage({ storeReseller, resellerId }: Props) {
    const [address, setAddress] = useLocalStorageStateObj<Address>('register.address', {
        postalCode: '',
        streetAddress: '',
        streetNumber: '',
        complement: '',
        reference: '',
        neighborhood: '',
        city: '',
        stateCode: '',
    });
    const [invalidAddress, setInvalidAddress] = React.useState(false);
    const [isPostalCodeApproved, setPostalCodeApproved] = React.useState<boolean>(); // undefined means unverified
    const [postalCodeInvalidMessage, setPostalCodeInvalidMessage] = React.useState<string>();

    const navigate = useNavigate();
    const submitEnabled = !invalidAddress;

    function submitForm(evt: any) {
        evt.preventDefault();
        if (!submitEnabled) return;

        const storeResellerForm = {
            ...storeReseller,
            address: {
                postal_code: address.postalCode,
                street_address: address.streetAddress,
                street_number: address.streetNumber,
                complement: address.complement,
                reference: address.reference,
                neighborhood: address.neighborhood,
                city: address.city,
                state_code: address.stateCode,
            },
        };

        navigate('/cadastro/pergunta', { state: { storeReseller: storeResellerForm, questionNumber: 1, resellerId } });
    }

    useAsyncEffect(
        async () => {
            setPostalCodeApproved(undefined);
            if (!address.postalCode) return;

            const clearPostalCode = address.postalCode.replace(/[\s.-]+/, '');
            if (clearPostalCode.length !== 8) return;

            setPostalCodeInvalidMessage(undefined);

            console.log('calling validateLocation', address.postalCode);
            try {
                return await executeRequest(urlJoin(OPS_API_URL, 'validate-location'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ postal_code: address.postalCode }),
                });
            } catch (error) {
                Sentry.captureException(error);
                setPostalCodeInvalidMessage('Erro ao verificar este CEP');
                return undefined;
            }
        },
        response => {
            if (!response) return;
            setPostalCodeApproved(response.success);
        },
        [address.postalCode]
    );

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>Endereço</Title>
            </TitleContainer>

            <Form onSubmit={submitForm}>
                <AddressForm
                    address={address}
                    onChangeAddress={(fieldsToUpdate: Partial<Address>) => {
                        if (fieldsToUpdate.postalCode) {
                            fieldsToUpdate.postalCode = removeTrailingNonDigits(
                                formatUsingMask(fieldsToUpdate.postalCode, '99999-999')
                            );
                        }
                        setAddress(prevState => ({ ...prevState, ...fieldsToUpdate }));
                    }}
                    onChangeInvalid={setInvalidAddress}
                    invalidMessage={postalCodeInvalidMessage}
                />
                {isPostalCodeApproved === false && (
                    <AlertContainer>
                        <AlertView>
                            <Info />
                            <p>
                                Poxa, ainda não chegamos na sua região! Finalize seu cadastro que vamos te avisar quando
                                chegarmos aí!
                            </p>
                        </AlertView>
                    </AlertContainer>
                )}
                <Padding />
                <SubmitButton disabled={!submitEnabled}>
                    <Title>Avançar</Title>
                </SubmitButton>
            </Form>
        </Container>
    );
}

export default RegistrationAddressPage;
