import styled from 'styled-components';
import { ReactComponent as InfoSVG } from './info.svg';

const InfoView = styled.div`
    display: flex;
`;

function Info() {
    return (
        <InfoView>
            <InfoSVG />
        </InfoView>
    );
}

export default Info;
