import * as React from 'react';
import * as Sentry from '@sentry/react';

export function useAsyncEffect<T>(
    prepare: () => Promise<T>,
    effect: (input: T) => void,
    deps?: React.DependencyList
): void {
    React.useEffect(() => {
        let active = true;
        (async () => {
            try {
                const input = await prepare();
                if (active) {
                    effect(input);
                }
            } catch (error) {
                Sentry.captureException(error);
            }
        })();
        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
