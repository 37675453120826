import styled from 'styled-components';
import { COLOR_GRAY } from '../style';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 20px;
`;

export const TotalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-weight: 700;
    font-size: 16px;

    padding: 16px;

    background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #ffffff;
`;

export const LineItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 16px;
`;

export const Padding = styled.div`
    width: 10px;
    height: 10px;
`;

export const InputsContainer = styled.div`
    margin: 0px 16px;
`;

export const AlertView = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${COLOR_GRAY};
    border-radius: 8px;
    padding: 16px;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;
`;

export const SubTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;

    margin: 20px;
`;
