import { Address } from '../../components/AddressForm';
import { executeRequest } from '../utils';

export async function resolveFromPostalCode(postalCode: string): Promise<Partial<Address>> {
    const response = await executeRequest(`https://viacep.com.br/ws/${postalCode}/json/`);

    if (response.erro) {
        throw new Error('error response from viacep for postal code');
    }

    return {
        streetAddress: response.logradouro,
        neighborhood: response.bairro,
        city: response.localidade,
        stateCode: response.uf,
    };
}
