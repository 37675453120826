import * as Sentry from '@sentry/react';

export async function executeRequest<T extends Record<string, any>>(
    url: string,
    fetchOptions?: RequestInit,
    options?: {
        omitResponseLog?: boolean;
        ignoreStatus?: boolean;
    }
): Promise<T> {
    console.debug(`Making request to ${url}`);

    const response = await fetch(url, fetchOptions);

    let textResponse: string;
    try {
        textResponse = await response.text();
    } catch (error) {
        console.error(`Request to ${url} failed`, error);

        throw error;
    }

    if (!response.ok && !options?.ignoreStatus) {
        console.error(`Got ${response.status} code from request to ${url}`, textResponse);

        const error = new Error(response.statusText);
        (error as Error & { response: Response }).response = response;

        throw error;
    }

    if (!textResponse) return {} as T;

    let parsed: T;
    try {
        parsed = JSON.parse(textResponse);
    } catch (_) {
        console.log(`Got invalid json response from ${url}`, textResponse);

        const error = Error('Invalid JSON: ' + textResponse);
        (error as Error & { response: Response }).response = response;

        Sentry.captureException(error);

        throw error;
    }

    if (options?.omitResponseLog) {
        console.debug(`Got response from ${url}`);
    } else {
        console.debug(`Got response from ${url}`, parsed);
    }

    return parsed;
}
