import * as React from 'react';
import styled from 'styled-components';

export interface CardProps {
    background?: boolean;
}

const StyledCard = styled.div`
    padding: 24px;
    gap: 16px;
    font-size: 12px;
    line-height: 16px;
`;

const StyledCardWithBackground = styled(StyledCard)`
    background: #f9f9f9;
`;

function Card({ background = true, children }: React.PropsWithChildren<CardProps>) {
    if (background) {
        return <StyledCardWithBackground>{children}</StyledCardWithBackground>;
    }
    return <StyledCard>{children}</StyledCard>;
}

export default Card;
