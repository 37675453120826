import * as React from 'react';
import styled from 'styled-components';
import Container from '../components/Container';
import CircledCheckMark from '../components/icons/CircledCheckmark';

import OrderStatusLayout from '../components/OrderStatusLayout';
import Title from '../components/Title';

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
        margin-right: 6px;
    }
`;

function PaidPage() {
    return (
        <OrderStatusLayout>
            <Container>
                <TitleContainer>
                    <CircledCheckMark />
                    <Title>Esse pedido já foi pago</Title>
                </TitleContainer>
                <p>
                    Oba! Agora é só esperar para receber! Se quiser comprar mais produtos, fale com seu contato Vendah.
                </p>
            </Container>
        </OrderStatusLayout>
    );
}

export default PaidPage;
