export const BLUE_PRIMARY_COLOR = '#38b1cd';
export const PINK_PRIMARY_COLOR = '#EB4E92';

export const INPUT_BORDER_COLOR = '#EDEDED';
export const INPUT_LABEL_COLOR = '#7F7F7F';
export const INPUT_PLACEHOLDER_COLOR = '#7F7F7F';

export const ERROR_COLOR = '#f44336';
export const COLOR_GRAY = '#ededed';
export const COLOR_BLUE = '#38b1cd';
export const COLOR_PINK = '#eb4e92';

export const INPUT_TEXT_COLOR = '#000000d6'; // rgba(0, 0, 0, 0.84)
export const INPUT_TEXT_DISABLED = '#00000061'; // rgba(0, 0, 0, 0.38)
