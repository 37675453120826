import styled from 'styled-components';

import { LogoContainer, Title, VendahLogo } from '../components';
import { Container, Padding, TitleContainer } from './styles';
import { Button } from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { StoreResellerRequest } from '../container/Registration.container';

const RegistrationMessageContainer = styled.div`
    margin: 0px 16px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    padding: 16px;
`;

type MessageType = 'INVITED' | 'ENABLED' | 'DISABLED' | 'DISABLED_MANUAL' | 'BLOCKED' | 'ERASED' | 'INFO';

interface Props {
    messageType: MessageType;
    storeReseller: Partial<StoreResellerRequest>;
    infoMessage?: string;
}

function RegistrationMessagePage({ messageType, storeReseller, infoMessage }: Props) {
    const navigate = useNavigate();

    function redirectBlocked() {
        window.location.assign('https://vendah.com.br/pages/bloqueio-de-conta');
    }

    function redirectDisabled() {
        window.location.assign('https://vendah.com.br/pages/sua-conta-esta-na-lista-de-espera-entenda-os-motivos');
    }

    function goBack() {
        navigate(-1);
    }

    function redirectSupport() {
        window.location.assign('https://linktr.ee/suportevendahbr');
    }

    function register() {
        navigate('/cadastro/email', { state: { storeReseller } });
    }

    function downloadApp() {
        window.location.assign('https://vendah.com.br/pages/baixe-o-app');
    }

    const configByCustomerState = {
        INVITED: {
            title: 'Parabéns, sua conta foi aprovada',
            description: '',
            button: [
                <Button color="pink" onPress={downloadApp}>
                    <Title>Ative a sua conta pelo app</Title>
                </Button>,
            ],
        },
        ENABLED: {
            title: 'Você já possui uma conta',
            description: 'Baixe o App e comece a ganhar ainda hoje.',
            button: [
                <Button color="blue" onPress={downloadApp}>
                    <Title>Baixe o App</Title>
                </Button>,
            ],
        },
        DISABLED: {
            title: 'Sua conta está na lista de espera',
            description:
                'Por enquanto ainda não conseguimos te receber na nossa comunidade de revendedoras, mas agradecemos muito pelo seu interesse em fazer parte da Vendah!',
            button: [
                <Button color="blue" onPress={redirectDisabled}>
                    <Title>Entenda os motivos</Title>
                </Button>,
                <Button color="blue" onPress={register}>
                    <Title>Refazer meu cadastro</Title>
                </Button>,
            ],
        },
        DISABLED_MANUAL: {
            title: 'Que bom que você voltou!!',
            description: 'Baixe o App para reativar a sua conta.',
            button: [
                <Button color="blue" onPress={downloadApp}>
                    <Title>Baixe o App</Title>
                </Button>,
            ],
        },
        BLOCKED: {
            title: 'Sua conta está bloqueada',
            description: 'Sua conta foi bloqueada pelas regras de uso da Vendah.',
            button: [
                <Button color="blue" onPress={redirectBlocked}>
                    <Title>Entenda os motivos</Title>
                </Button>,
            ],
        },
        ERASED: {
            title: 'Sua conta está em processo de exclusão',
            description: 'Caso queira interromper esse processo entre em contato com o nosso suporte.',
            button: [
                <Button color="blue" onPress={redirectSupport}>
                    <Title>Fale com o suporte</Title>
                </Button>,
                <Button color="blue" onPress={goBack}>
                    <Title>Voltar</Title>
                </Button>,
            ],
        },
        INFO: {
            title: 'Cadastro',
            description: infoMessage,
            button: [
                <Button color="blue" onPress={goBack}>
                    <Title>Voltar</Title>
                </Button>,
            ],
        },
    };

    const config = configByCustomerState[messageType];

    return (
        <Container>
            <RegistrationMessageContainer>
                <LogoContainer>
                    <VendahLogo />
                </LogoContainer>
                <TitleContainer>
                    <Title>{config.title}</Title>
                </TitleContainer>
                <DescriptionContainer>
                    <p>{config.description}</p>
                </DescriptionContainer>
                <Padding />
                {config.button.map((button, index) => (
                    <ButtonContainer key={index}>{button}</ButtonContainer>
                ))}
            </RegistrationMessageContainer>
        </Container>
    );
}

export default RegistrationMessagePage;
