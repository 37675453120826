export interface CheckboxProps {
    checked: boolean;
    onChange: () => void;
}

function Checkbox({ checked, onChange }: CheckboxProps) {
    return (
        <div onClick={onChange}>
            <input type="checkbox" checked={checked} onChange={onChange} />
        </div>
    );
}
export default Checkbox;
