import * as React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    text-align: center;
`;

function Container({ children }: React.PropsWithChildren) {
    return <StyledContainer>{children}</StyledContainer>;
}

export default Container;
