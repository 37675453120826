import { useLocation } from 'react-router-dom';
import RegistrationMessagePage from '../screens/RegistrationMessage.page';
import RegistrationEmailPage from '../screens/RegistrationEmail.page';

function RegistrationMessageContainer(): JSX.Element | null {
    const { state } = useLocation();

    if (!state) return <RegistrationEmailPage storeReseller={{}} />;

    return (
        <RegistrationMessagePage
            storeReseller={state.storeReseller}
            messageType={state.messageType}
            infoMessage={state.infoMessage}
        />
    );
}

export default RegistrationMessageContainer;
