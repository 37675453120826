import * as React from 'react';

import Card from '../components/Card';
import Container from '../components/Container';
import Boleto from '../components/icons/Boleto';
import OrderStatusLayout from '../components/OrderStatusLayout';
import Title from '../components/Title';

function ExpiredPage() {
    return (
        <OrderStatusLayout>
            <Container>
                <Title>Prazo esgotado</Title>

                <p>Ainda não detectamos o pagamento deste pedido e o prazo já acabou.</p>
            </Container>

            <Card>
                <Title>
                    <Boleto /> Pagamento via Boleto
                </Title>

                <p>
                    Se você já realizou o pagamento via boleto, aguarde. Pode levar até
                    <b> 2 dias úteis</b> para atualizar o status do seu pedido.
                </p>
            </Card>
            <Card background={false}>
                <p>
                    Se você não realizou o pagamento, mas ainda quer fazer a compra, fale com seu contato Vendah e{' '}
                    <b>solicite um novo link.</b>
                </p>
            </Card>
        </OrderStatusLayout>
    );
}

export default ExpiredPage;
