import * as React from 'react';

import Card from '../components/Card';
import Container from '../components/Container';
import OrderStatusLayout from '../components/OrderStatusLayout';
import Title from '../components/Title';

function CancelledPage() {
    return (
        <OrderStatusLayout>
            <Container>
                <Title>Pedido cancelado</Title>

                <p>Esse pedido foi cancelado e por isso não é possível realizar o pagamento.</p>
            </Container>
            <Card background={false}>
                <p>
                    Se ainda quer fazer a compra, fale com seu contato Vendah e <b>solicite um novo link.</b>
                </p>
            </Card>
        </OrderStatusLayout>
    );
}

export default CancelledPage;
