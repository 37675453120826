import * as React from 'react';

import * as Sentry from '@sentry/react';
import Card from '../components/Card';
import VendahLogo from '../components/icons/VendahLogo';
import { LayoutContainer, LogoContainer } from '../components/OrderStatusLayout';
import Title from '../components/Title';

export interface ErrorBoundaryState {
    error: Error | null;
}

export interface ErrorBoundaryProps {
    error?: Error;
}

class ErrorBoundary extends React.Component<React.PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
    constructor(props: React.PropsWithChildren<ErrorBoundaryProps>) {
        super(props);

        this.state = { error: props.error ?? null };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        console.error(error);
        return { error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        Sentry.captureException(error, { extra: { errorInfo } });
    }

    render() {
        const { error } = this.state;
        const { children } = this.props;

        if (error) {
            return (
                <LayoutContainer>
                    <LogoContainer>
                        <VendahLogo />
                    </LogoContainer>
                    <section>
                        <Card>
                            <Title>Erro!</Title>

                            <p>{error.message}</p>
                        </Card>
                    </section>
                </LayoutContainer>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
