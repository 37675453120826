export interface RadioButtonProps {
    label: string;
    checked: boolean;
    onChange: () => void;
}

function RadioButton({ label, checked, onChange }: RadioButtonProps) {
    return (
        <div onClick={onChange}>
            <input type="radio" checked={checked} value={label} onChange={onChange} />
            <label> {label}</label>
        </div>
    );
}
export default RadioButton;
