import * as React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.strong`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    text-align: center;
`;

function Title({ children }: React.PropsWithChildren) {
    return <StyledTitle>{children}</StyledTitle>;
}

export default Title;
