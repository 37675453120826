import styled from 'styled-components';

import { formatAmountBrl } from '../utils/format';
import ProgressBar from './ProgressBar';
import { COLOR_PINK } from '../style';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0px 16px;
`;

export interface FreeShippingCardProps {
    price: number;
    priceToFreeShipping: number;
}

function FreeShippingCard({ price, priceToFreeShipping }: FreeShippingCardProps) {
    if (priceToFreeShipping === 0) {
        return null;
    }

    const isFree = price >= priceToFreeShipping;

    return (
        <Container>
            {isFree && (
                <p>
                    Você conseguiu <strong>frete grátis</strong>! Aproveite e compre mais!
                </p>
            )}
            {!isFree && (
                <p>
                    Faltam {formatAmountBrl(priceToFreeShipping - price)} para obter <strong>frete grátis</strong>
                </p>
            )}
            <ProgressBar
                currentValue={Math.min(price, priceToFreeShipping)}
                limitLow={0}
                limitHigh={priceToFreeShipping}
                lastLevel={false}
                barColor={COLOR_PINK}
            />
        </Container>
    );
}
export default FreeShippingCard;
