import styled from 'styled-components';
import { COLOR_GRAY } from '../style';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const LineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const BarContainer = styled.div`
    padding-top: 10px;
`;

const BarColorContainer = styled.div`
    padding-top: 10px;
`;

export interface ProgressBarProps {
    currentValue: number;
    limitLow: number;
    limitHigh: number;
    lastLevel: boolean;
    barColor: string;
}

function ProgressBar({ currentValue, limitLow, limitHigh, barColor }: ProgressBarProps) {
    return (
        <Container style={{ width: '100%' }}>
            <BarContainer style={{ width: '100%', height: 10, backgroundColor: COLOR_GRAY, borderRadius: 10 }}>
                <BarColorContainer
                    style={{
                        position: 'absolute',
                        backgroundColor: barColor,
                        borderRadius: 10,
                        width: `${((currentValue - limitLow) * 100) / (limitHigh - limitLow)}%`,
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        height: 10,
                    }}
                />
            </BarContainer>
            <LineContainer>
                <p>R$ {limitLow}</p>
                <p>R$ {limitHigh}</p>
            </LineContainer>
        </Container>
    );
}
export default ProgressBar;
