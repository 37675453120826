export function formatAmountBrl(amount: number): string {
    return `R$ ${amount.toFixed(2).replace('.', ',')}`;
}

export function formatUsingMask(value: string, mask: string): string {
    value = value.replace(/\D/g, '');
    let maskedValue = '';
    let indexValue = 0;
    for (let indexMask = 0; indexMask < mask.length; indexMask++) {
        if (mask[indexMask] === '9') {
            if (indexValue >= value.length) {
                break;
            }
            maskedValue += value[indexValue++];
        } else {
            maskedValue += mask[indexMask];
        }
    }
    return maskedValue;
}

export function formatPhone(value?: string): string {
    if (!value || value === '') return '';
    const phoneWithoutBRCode = value.replace(/\+55/, '');
    return formatUsingMask(phoneWithoutBRCode, '(99) 99999-9999');
}

export function formatOnlyDigits(value: string): string {
    if (!value) return value;
    return value.replace(/\D/g, '');
}

export function stripNonLetters(value: string): string {
    return value.replace(/[^a-zA-ZÀ-ÿ ]/g, '');
}

export function isEmailValid(email: string): boolean {
    return !!email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]+(\.[a-zA-Z]+)?$/);
}

export function removeTrailingNonDigits(value: string) {
    return value.replace(/\D+$/, '');
}
